<template>
  <div>
    <form-wizard
      color="#ff9900"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- details tab -->
      <tab-content
        title="Match Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-form
            id="match-form"
            class="p-2"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Enter Match Account Details.
                </h5>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Title"
                  label-for="title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="data_local.title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Match Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Match At"
                  label-for="match-at"
                >
                  <flat-pickr
                    v-model="data_local.match_at"
                    :config="{ dateFormat: 'Y-m-d H:i:s', enableTime: true, }"
                    class="form-control"
                    name="match_at"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Entry Cost (per Player)"
                  label-for="entry-cost"
                >
                  <span class="font-weight-bolder h5">{{ data_local.entry_cost | currency }}</span>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Max Players"
                  label-for="max-players"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="max players"
                    rules="required"
                  >
                    <b-form-input
                      id="max-players"
                      v-model="data_local.max_players"
                      type="number"
                      min="0"
                      name="max_players"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Max Players"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                class="hidden"
              >
                <b-form-group
                  label="Tournament"
                  label-for="add-tournament"
                >
                  <v-select
                    v-model="data_local.pubg_tournament_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tournamentOption"
                    :reduce="label => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-tournament"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Map"
                  rules="required"
                >
                  <b-form-group
                    label="Map"
                    label-for="add-map"
                  >
                    <v-select
                      v-model="data_local.map_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="mapOption"
                      :reduce="label => label.code"
                      label="label"
                      :clearable="true"
                      input-id="change-map"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Mode"
                  rules="required"
                >
                  <b-form-group
                    label="Mode"
                    label-for="add-mode"
                  >
                    <v-select
                      v-model="data_local.mode_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="modeOption"
                      :reduce="label => label.code"
                      label="label"
                      :clearable="true"
                      input-id="change-mode"
                      @input="calculateSlotCost()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="View"
                  rules="required"
                >
                  <b-form-group
                    label="View"
                    label-for="add-view"
                  >
                    <v-select
                      v-model="data_local.view_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="viewOption"
                      :reduce="label => label.code"
                      label="label"
                      :clearable="true"
                      input-id="change-view"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Slot Cost"
                ><span class="font-weight-bolder h5">{{ slotCost | currency }}</span>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="data_local.emulator"
                    switch
                    inline
                  >
                    Is Emulator Allowed?
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col
                md="6"
              >
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="data_local.paid"
                    switch
                    inline
                  >
                    Is Paid Allowed?
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col
                md="6"
                class="mt-2"
              >
                <b-form-group
                  label="Organization"
                  label-for="add-organization"
                >
                  <v-select
                    v-model="data_local.organization_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="organizationOption"
                    :reduce="label => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-organization"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                class="mt-2 d-none"
              >
                <b-form-group
                  label="Kill Reward"
                  label-for="kill-reward"
                >
                  <b-form-input
                    id="kill-reward"
                    v-model="data_local.kill_reward"
                    type="number"
                    name="kill_reward"
                    placeholder="Kill Reward"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                class="mt-2 d-none"
              >
                <b-form-group
                  label="Prize"
                  label-for="prize"
                >
                  <b-form-input
                    id="prize"
                    v-model="data_local.prize"
                    type="number"
                    name="prize"
                    placeholder="Prize"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                class="d-none"
              >
                <b-form-group
                  label="Status"
                  label-for="add-status"
                >
                  <v-select
                    v-model="data_local.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="matchStatusOption"
                    :reduce="label => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-status"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                class="hidden"
              >
                <b-form-group
                  label="Room Id"
                  label-for="room_id"
                >
                  <b-form-input
                    id="room_id"
                    v-model="data_local.room_id"
                    name="room_id"
                    placeholder="Room Id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                class="hidden"
              >
                <b-form-group
                  label="Password"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    v-model="data_local.password"
                    name="password"
                    placeholder="Password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
      <!-- Price Content -->
      <tab-content
        title="Price"
        icon="feather icon-check-circle"
        :before-change="validatePrize"
      >
        <b-form
          id="price-form"
          class="p-2"
        >
          <validation-observer
            ref="prizeRules"
            tag="form"
          >
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-group
                    label="Reward Type"
                    label-for="add-price-type"
                  >
                    <v-select
                      v-model="data_local.prize_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="priceTypeOption"
                      :reduce="label => label.code"
                      label="label"
                      :clearable="true"
                      input-id="change-priceType"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
              >
                <div class="demo-inline-spacing">
                  <b-form-checkbox
                    v-model="data_local.kills_enabled"
                    switch
                    inline
                  >
                    Enable Kill Points
                  </b-form-checkbox>
                </div>
              </b-col>
              <b-col
                md="6"
                class="mt-2"
              >
                <b-form-group
                  label="Match Rewards"
                  label-for="match-price"
                >
                  <div
                    v-for="(input,k) in match_rewards"
                    :key="k"
                    class="form-group"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="'match_price['+(k+1)+']'"
                      :rules="'required|max_value:'+getMaxPrice(k)"
                    >
                      <b-input-group
                        prepend="₹"
                      >
                        <b-form-input
                          id="match-price"
                          v-model="input.price"
                          type="number"
                          min="0"
                          :max="getMaxPrice(k)"
                          :name="'match_price['+(k+1)+']'"
                          :placeholder="(k+1)+' Place Reward / Prize'"
                        />
                        <b-input-group-append>
                          <b-button
                            v-if="k || ( !k && match_rewards.length > 1)"
                            v-b-tooltip.hover.top="' Remove Reward'"
                            variant="outline-primary"
                            class="btn-danger"
                            @click="remove(k)"
                          >
                            <feather-icon
                              icon="MinusCircleIcon"
                            />
                          </b-button>
                          <b-button
                            v-if="k == match_rewards.length-1"
                            v-b-tooltip.hover.top="'Add Reward'"
                            variant="outline-primary"
                            class="btn-success"
                            @click="add(k)"
                          >
                            <feather-icon icon="PlusCircleIcon" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                Maximum Expected Entry : <span class="h5 font-weight-bolder">{{ data_local.max_players* data_local.entry_cost | currency }}</span><br>
                Total Rewards  : <span
                  class="h5 font-weight-bolder"
                  :class="{ 'text-warning' : getRemainingBalance() < 0}"
                >{{ getSumOfRewards() | currency }}</span><br>
                Approximate Balance  : <span
                  class="h5 font-weight-bolder text-success"
                  :class="{ 'text-danger' : getRemainingBalance() < 0}"
                >{{ getRemainingBalance() | currency }}</span><br>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </tab-content>
      <!-- Rules  -->
      <tab-content
        title="Rule"
        icon="feather icon-check-circle"
      >
        <b-row>
          <b-col class="md-8" />
          <b-col class="md-4">
            <b-form class="faq-search-input">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchbar"
                  v-model="faqSearchQuery"
                  placeholder="Search Rule..."
                />
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>

        <section
          id="faq-tabs"
          class="mt-2 mb-2"
        >
          <div v-if="faqData.length">
            <b-tabs
              vertical
              content-class="col-12 col-md-8 col-lg-9"
              pills
              nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
              nav-class="nav-left"
            >

              <b-tab
                v-for="(obj, index) in faqData"
                :key="index"
                :active="!index"
              >

                <!-- title -->
                <template #title>
                  <span class="font-weight-bold">{{ obj.title }}</span>
                </template>

                <div v-if="obj">
                  <div class="d-flex align-items-center mt-1 mb-2">
                    <div>
                      <h4 class="mb-0">
                        {{ obj.title }}
                      </h4>
                    </div>
                  </div>
                  <b-list-group>
                    <b-list-group-item
                      v-for="(data,indexes) in obj.descriptions"
                      :key="indexes"
                    > <b-form-checkbox
                      v-model="data_local.rules_id"
                      :value="data.id"
                    >{{ data.description }}</b-form-checkbox></b-list-group-item>
                  </b-list-group>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div
            v-else
            class="text-center"
          >
            <span>No Rule Found</span>
          </div>
        </section>

      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  VBTooltip,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { required, url } from '@validations'
import { max_value } from 'vee-validate/dist/rules'

import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import matchStoreModule from '../matchStoreModule'

extend('max_value', max_value)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      url,
      faqSearchQuery: '',
      faqData: {},
      matchStatusOption: [],
      organizationOption: [],
      priceTypeOption: [],
      tournamentOption: [],
      mapOption: [],
      modeOption: [],
      viewOption: [],
      match_rewards: [{
        price: '',
      }],
      slotCost: 0,
      data_local: {
        title: '',
        prize_type: '',
        kill_enabled: 0,
        organization_id: '',
        pubg_tournament_id: null,
        image: '',
        map_id: '',
        mode_id: '',
        view_id: '',
        max_players: '',
        emulator: 0,
        paid: 0,
        entry_cost: '',
        kill_reward: '',
        prize: '',
        room_id: '',
        password: '',
        match_at: '',
        status: 2,
        rules_id: [],
        match_price: [],
      },
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-matches'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, matchStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  created() {
    this.dropdownValues()
    this.fetchMatchData(this.$route.params.id)
  },
  methods: {
    add() {
      this.match_rewards.push({
        price: '',
      })
    },
    remove(index) {
      this.match_rewards.splice(index, 1)
    },
    calculateSlotCost() {
      this.$http.get('/match/getSlotPrize', { params: { entry_cost: this.data_local.entry_cost, mode_id: this.data_local.mode_id } })
        .then(res => {
          this.slotCost = res.data.data.value
        })
    },
    formSubmitted() {
      this.$store.dispatch('app-matches/updateMatch', {
        data: this.data_local,
        id: this.$route.params.id,
      })
        .then(res => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Match Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Match updated successfully',
              },
            })
            this.$router.push('/matches/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Match updation Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Please try again after sometime',
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Oops! Match updation Failed',
              icon: 'BellIcon',
              variant: 'danger',
              text: 'Please try again after sometime',
            },
          }, {
            timeout: 3000,
          })
        })
    },
    validationForm() {
      if (this.data_local.match_at === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Match At',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Kindly select the match date and time',
          },
        })
        return false
      }
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getSumOfRewards() {
      return this.match_rewards.reduce((a, b) => {
        let c = 0
        if (b.price !== '') {
          c = b.price
        }
        return parseInt(a, 10) + parseInt(c, 10)
      }, 0)
    },
    getRemainingBalance() {
      let sumOfRewards = 0
      if (this.getSumOfRewards()) {
        sumOfRewards = this.getSumOfRewards()
      }
      return this.getMaximumCollection() - sumOfRewards
    },
    getMaximumCollection() {
      return this.data_local.entry_cost * this.data_local.max_players
    },
    validatePrize() {
      if (this.getRemainingBalance() < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rewards Error',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Total Reward is exceeding Collection',
          },
        })
        return false
      }
      return new Promise((resolve, reject) => {
        this.$refs.prizeRules.validate().then(success => {
          if (success) {
            this.matchPrizeCalculation()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    matchPrizeCalculation() {
      this.data_local.prize = this.getSumOfRewards()
      const maximumCollection = this.getMaximumCollection()
      this.data_local.status = 1
      this.data_local.prize_attributes = {
        type: this.data_local.prize_type,
        kills: this.data_local.kill_enabled,
        rewards: {},
      }
      // eslint-disable-next-line array-callback-return
      this.match_rewards.map((value, key) => {
        this.data_local.prize_attributes.rewards[`${key + 1}`] = { title: key + 1, max_prize: value.price, percentage: (value.price / maximumCollection) * 100 }
      })
    },
    getMaxPrice(k) {
      let maxPrice = this.data_local.entry_cost * this.data_local.max_players
      if (k) {
        maxPrice = this.match_rewards[k - 1].price
      }
      return maxPrice
    },
    fetchData() {
      this.$http.get('/rule/list/form', { params: { q: this.faqSearchQuery } })
        .then(res => {
          this.faqData = res.data.data
        })
    },
    dropdownValues() {
      this.$http.get('/list/getUserOrganization')
        .then(res => {
          this.organizationOption = res.data.data
          if (res.data.success) {
            this.data_local.organization_id = ''
            const organizationSelectedLocal = localStorage.getItem('organizationSelected')
            if (organizationSelectedLocal !== 'null') {
              this.data_local.organization_id = parseInt(organizationSelectedLocal, 10)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Organization fetching failed',
                icon: 'BellIcon',
                text: 'Oops! Something Went Wrong',
                variant: 'danger',
              },
            }, {
              timeout: 3000,
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Organization fetching failed',
              icon: 'BellIcon',
              text: 'Oops! Something Went Wrong',
              variant: 'danger',
            },
          }, {
            timeout: 3000,
          })
          return error
        })
      this.$http.get('/list/getPriceType')
        .then(res => {
          this.priceTypeOption = res.data.data
        })
        .catch(error => {
          // console.log(error)
        })
      this.$http.get('/list/getMatchStatus')
        .then(res => {
          this.matchStatusOption = res.data.data
        })
        .catch(error => error)
      this.$http.get('/list/getPubgMaps')
        .then(res => {
          this.mapOption = res.data.data
        })
        .catch(error => error)
      this.$http.get('/list/getPubgModes')
        .then(res => {
          this.modeOption = res.data.data
        })
        .catch(error => error)
      this.$http.get('/list/getPubgViews')
        .then(res => {
          this.viewOption = res.data.data
        })
        .catch(error => error)
    },
    fetchMatchData(id) {
      this.$store.dispatch('app-matches/fetchMatch', id)
        .then(res => {
          this.data_local = res.data.data
          this.data_local.emulator = !!res.data.data.emulator
          this.data_local.paid = !!res.data.data.paid
          this.slotCost = res.data.data.slot_price
          this.match_rewards = []
          this.data_local.prizes.rewards.forEach(value => {
            this.match_rewards.push({ price: value.max_prize })
          })
        })
        .catch(error => {
          // console.log(error)
          if (error.response.status === 404) {
            return error
          }
          return error
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/pages/page-faq.scss';
</style>
