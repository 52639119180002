import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMatches(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/match', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMatchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/match/${queryParams.matchId}/edit`, { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMatch(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/match/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMatch(ctx, matchData) {
      return new Promise((resolve, reject) => {
        axios.post('/match', matchData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            if (error.response.status === 422) {
              if (typeof error.response.data.errors !== 'undefined') {
                const keys = Object.keys(error.response.data.errors)
                const values = Object.values(error.response.data.errors)
                for (let i = 0; i <= keys.length; i += 1) {
                  if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                    reject(new Error(values[0][0]))
                  }
                }
              }
            } else {
              reject(error)
            }
          })
      })
    },
    updateMatch(ctx, match) {
      return new Promise((resolve, reject) => {
        axios.patch(`/match/${match.id}`, match.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            if (error.response.status === 422) {
              if (typeof error.response.data.errors !== 'undefined') {
                const keys = Object.keys(error.response.data.errors)
                const values = Object.values(error.response.data.errors)
                for (let i = 0; i <= keys.length; i += 1) {
                  if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                    reject(new Error(values[0][0]))
                  }
                }
              }
            } else {
              reject(error)
            }
          })
      })
    },
    addResult(ctx, matchData) {
      return new Promise((resolve, reject) => {
        axios.post('/match/addMatchResult', matchData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            // console.log(error)
            if (error.response.status === 422) {
              if (typeof error.response.data.errors !== 'undefined') {
                const keys = Object.keys(error.response.data.errors)
                const values = Object.values(error.response.data.errors)
                for (let i = 0; i <= keys.length; i += 1) {
                  if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                    reject(new Error(values[0][0]))
                  }
                }
              }
            } else {
              reject(error)
            }
          })
      })
    },
    removeMatch(ctx, matchId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/match/${matchId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
